import React, { useRef, useEffect, useMemo } from 'react';
import { useIframeMessaging } from './hooks/useIframeMessaging';

const NewAppIFrame = ({ iFrameOrigin, iFramePagePath, title, authToken, userId, language, onLanguageChange }) => {
    const iframeRef = useRef(null);

    const initialMessages = useMemo(() => [
        { type: 'userId', value: userId },
        { type: 'auth', value: authToken },
        { type: 'language', value: language },
    ], [authToken, language, userId]);

    const { sendMessage, handleLoad } = useIframeMessaging(iframeRef, iFrameOrigin, initialMessages);

    useEffect(() => {
        sendMessage('language', language);
    }, [language, sendMessage]);

    useEffect(() => {
        if (onLanguageChange) {
            onLanguageChange(sendMessage);
        }
    }, [onLanguageChange, sendMessage]);

    console.log('iFrameOrigin: ', iFrameOrigin);
    console.log('iFramePagePath: ', iFramePagePath);

    return (
        <iframe
            ref={iframeRef}
            src={`${iFrameOrigin}/${iFramePagePath}`}
            style={{ width: '100%', height: '94dvh', border: 'none', overflow: 'hidden'}}
            onLoad={handleLoad}
            title={title}
        />
    );
};

export default NewAppIFrame;
