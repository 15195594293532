import React, { useState, useEffect, useCallback } from 'react'
import clientConfig from 'client-config.js';
import jwt from "jwt-decode";
import NewAppIFrame from 'components/NewAppIFrameViewer';

function AssessmentsCategories() {
  const [language, setLanguage] = useState(() => localStorage.getItem('i18nextLng') || 'en');
  const paths = window.location.href.split('/')
  const categoryId = paths[paths.length - 1] !== '' ? paths[paths.length - 1] : paths[paths.length - 2]

  const jwtToken = jwt(localStorage.getItem('token'));
  const token = localStorage.getItem('token');


  const handleLanguageChange = useCallback((sendMessage) => {
    const languageChangeListener = (event) => {
      if (event.key === 'i18nextLng') {
        const newLanguage = event.newValue;
        setLanguage(newLanguage);
        sendMessage('language', newLanguage);
      }
    };

    // Add event listener for storage changes
    window.addEventListener('storage', languageChangeListener);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('storage', languageChangeListener);
  }, []);

  // Effect to initialize language
  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
    }
  }, []);

  return (
    <NewAppIFrame
      iFrameOrigin={clientConfig.nextUrl}
      iFramePagePath={`assessments/${categoryId}`}
      title="Assessments"
      authToken={token}
      userId={jwtToken.data.user.id}
      language={language}
      onLanguageChange={handleLanguageChange}
    />
  )
}

export default AssessmentsCategories
