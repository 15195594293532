import React, { CSSProperties } from 'react';
import { default as CustomModal } from '../../../components/Modal/Modal.jsx';

// @ts-ignore
import loadinggif from "../../../assets/img/loading.gif";
import ActionButton from '../../../components/CustomButtons/ActionButton.jsx';


const pStyle: {
    modalTittle: CSSProperties;
    modalBody: CSSProperties;
} = {
    modalTittle: {
        alignSelf: "flex-start",
    },
    modalBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "auto",
        maxHeight: "60dvh",
    },
};

{/*
  customModalProps: {
  showModal,
  setShowModal,
  textModal,
  buttonAction,

  } */}

type Props = {
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  textBody?: string,
  bodyComponent?: React.ReactNode,
}

    const TermsAndCondition = ({showModal, setShowModal, textBody, bodyComponent} : Props) => {
        return (
            <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop={true}
            >
                <div style={{ maxHeight: "70dvh",  }}>
                    <div id="loadinggd2" style={{ display: "none" }}>
                        <img
                            className="responsive"
                            src={loadinggif}
                            width="25"
                            alt=""
                            style={{ marginBottom: "20px" }}
                        />
                    </div>
                    <h1 style={pStyle.modalTittle}>Terms and Conditions</h1>
                    <div
                        style={pStyle.modalBody}
                    >
                        {textBody && <p>{textBody}</p>}

                            {bodyComponent && bodyComponent}

                        <ActionButton
                            size="auto"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Close
                        </ActionButton>
                    </div>
                </div>
            </CustomModal>
        )
    };

    export default TermsAndCondition;
