import { useCallback, useEffect, useRef } from 'react';

export const useIframeMessaging = (iframeRef, iFrameOrigin, initialMessages) => {
  const isIframeLoaded = useRef(false);

  const sendMessage = useCallback((messageType, messageValue) => {
    const message = {
      type: messageType,
      value: messageValue
    };

    if (isIframeLoaded.current && iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(message, iFrameOrigin);
    } else {
      console.log("iframe not ready, message queued");
    }
  }, [iFrameOrigin, iframeRef]);

  const handleLoad = useCallback(() => {
    isIframeLoaded.current = true;
    initialMessages.forEach(message => {
      sendMessage(message.type, message.value)
    });
  }, [sendMessage, initialMessages]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== iFrameOrigin) return;

      if (event.data.type === 'navigate') {
        window.location.href = event.data.path;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iFrameOrigin]);

  return { sendMessage, handleLoad };
};
