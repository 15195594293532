import React from 'react';

const SwedbankTermsAndConditions = () => {
    const styles = {
        container: {
          maxHeight: "100%",
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            fontFamily: 'sans-serif',
            lineHeight: '1.75'
        },
        heading2: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginTop: '32px'
        },
        heading3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            marginTop: '16px'
        },
        paragraph: {
            marginTop: '16px'
        },
        link: {
            color: '#3b82f6',
            textDecoration: 'underline'
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading2}>
                Information om behandlingen av dina personuppgifter
            </h2>
            <h3 style={styles.heading3}>
                1. Vi är personuppgiftsansvariga – hur kontaktar du oss?
            </h3>
            <p style={styles.paragraph}>
                Ramboll Management Consulting äger och driver Incept Sustainability
                Platform (nedan kallad Plattformen).
            </p>
            <p style={styles.paragraph}>
                Du är välkommen att kontakta oss om du har några frågor om behandlingen:
            </p>
            <p style={styles.paragraph}>
                Rambøll Management Consulting
                <br />
                Hannemanns Allé 53
                <br />
                2300 Köpenhamn
                <br />
                CVR-nr: 60997918
                <br />
                Telefon: +45 5161 2506
                <br />
                E-post:{' '}
                <a href='mailto:PRIVACY@ramboll.com' style={styles.link}>
                    PRIVACY@ramboll.com
                </a>
            </p>

            <h3 style={styles.heading3}>
                2. Ändamålen och den rättsliga grunden för behandlingen av dina
                personuppgifter
            </h3>
            <p style={styles.paragraph}>
                Vid tillgängliggörande av Plattformen behandlas dina personuppgifter av
                Ramboll Management Consulting (RMC) i egenskap av personuppgiftsansvarig.
                Syftet med sådan behandling är att administrera åtkomst till plattformen
                och tillhandahålla support på Plattformen.
            </p>
            <p style={styles.paragraph}>
                De personuppgifter som RMC behandlar för ovan ändamål är de uppgifter som
                du tillhandahåller Plattformen vid registrering av en användarprofil:
                namn, titel, organisation, e-postadress.
            </p>
            <p style={styles.paragraph}>
                Den rättsliga grunden för behandlingen är Ramboll Management Consultings
                berättigade intresse av att tillhandahålla en tjänst (Plattformen) till
                den organisation som plattformen är tillgänglig för (artikel 6.1 f i
                dataskyddsförordningen).
            </p>

            <h3 style={styles.heading3}>
                3. Mottagare eller kategorier av mottagare
            </h3>
            <p style={styles.paragraph}>
                Våra leverantörer kan ha teknisk tillgång till dina personuppgifter i
                samband med tillhandahållandet av sina tjänster som personuppgiftsbiträde
                till oss. Detta inkluderar till exempel hosting och support av
                Plattformen. Dina personuppgifter delas inte med andra än sådana
                leverantörer.
            </p>

            <h3 style={styles.heading3}>
                4. Överföring till stödmottagare i tredjeländer, inklusive internationella
                organisationer
            </h3>
            <p style={styles.paragraph}>
                Som huvudregel lagras och behandlas dina personuppgifter inom EU/EES.
            </p>

            <h3 style={styles.heading3}>5. Lagring av dina personuppgifter</h3>
            <p style={styles.paragraph}>
                Ramboll Management Consulting kommer att lagra dina personuppgifter till
                dess att din användarprofil raderas i Plattformen.
            </p>
            <p style={styles.paragraph}>
                Om din användarprofil raderas, kommer dina svar att behållas i
                anonymiserad form på plattformen.
            </p>

            <h3 style={styles.heading3}>
                6. Automatiserat beslutsfattande, inklusive profilering
            </h3>
            <p style={styles.paragraph}>
                Automatiserat beslutsfattande, inklusive profilering, används inte för att
                behandla dina personuppgifter.
            </p>

            <h3 style={styles.heading3}>7. Dina rättigheter</h3>
            <p style={styles.paragraph}>
                Enligt dataskyddsförordningen har du ett antal rättigheter i förhållande
                till behandlingen av dina personuppgifter.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt att se information (rätt till tillgång)</strong>
                <br />
                Du har rätt att få tillgång till de personuppgifter vi behandlar om dig.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt till rättelse (rättelse)</strong>
                <br />
                Du har rätt att få felaktiga personuppgifter rättade.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt till radering</strong>
                <br />I särskilda fall har du rätt att få personuppgifter raderade.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt till begränsning av behandling</strong>
                <br />
                Du har i vissa fall rätt att få behandlingen av dina personuppgifter
                begränsad. Om du har rätt att få behandlingen begränsad får vi i framtiden
                endast behandla uppgifterna – med undantag för lagring – med ditt
                samtycke, eller i syfte att fastställa, göra gällande eller försvara
                rättsliga anspråk, eller för att skydda en person eller viktiga allmänna
                intressen.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt att göra invändningar</strong>
                <br />
                Du har i vissa fall rätt att invända mot vår eller lagliga behandling av
                dina personuppgifter. Du kan också invända mot behandlingen av dina
                uppgifter för direktmarknadsföringsändamål.
            </p>
            <p style={styles.paragraph}>
                <strong>Rätt att överföra information (dataportabilitet)</strong>
                <br />I vissa fall har du rätt att få dina personuppgifter i ett
                strukturerat, allmänt använt och maskinläsbart format och att få dessa
                personuppgifter överförda från en personuppgiftsansvarig till en annan
                utan hinder.
            </p>
            <p style={styles.paragraph}>
                Du kan läsa mer om dina rättigheter på Integritetsskyddsmyndighetens
                hemsida: på{' '}
                <a href='https://www.imy.se' style={styles.link}>
                    www.imy.se
                </a>
            </p>
            <p style={styles.paragraph}>
                Om du vill utöva dina rättigheter är du välkommen att kontakta oss (se
                kontaktuppgifter under avsnitt 1).
            </p>

            <h3 style={styles.heading3}>
                8. Klagomål till dataskyddsmyndigheten
            </h3>
            <p style={styles.paragraph}>
                Du har rätt att lämna in ett klagomål till den danska
                dataskyddsmyndigheten om du är missnöjd med hur dina personuppgifter
                behandlas. Du hittar den danska dataskyddsmyndighetens kontaktuppgifter på{' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
        </div>
    );
};

export default SwedbankTermsAndConditions;
