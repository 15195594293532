/*!
 * Description: this file contains the main base component for
 *  the rendering of the modules
 * Components: GuideSectionManager, Guide
 */

import React from "react";
import { Redirect } from "react-router-dom";
import ScrollToTop from "components/Scroll/ScrollToTop.js";
import Pagination from "components/Pagination/Pagination.js";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { get_jsons, isAuthenticated } from "assets/jss/incept-sustainability-variables.jsx";
import jwt from "jwt-decode";
import GuideSectionManager from "components/GuidePage/GuideSectionManager.js"

//Translations
import { withTranslation, Trans } from 'react-i18next';
import i18n from "i18next";

/*
 * Description: this component renders renders
 * the whole module/guide and the pagination
 */
class Guide extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		var decodedtoken = "";
	    var redi = false;
		if (!isAuthenticated()) {
				redi = true;
		}
		else 
        	decodedtoken = jwt(localStorage.getItem( 'token' ));
		this.state = {
			module: null,
			loading: true,
			iskb: (this.props.match.params.typemod!==undefined && this.props.match.params.typemod.includes("KB")) ? true:false,
			typemod: this.props.match.params.typemod!==undefined ? this.props.match.params.typemod:"act",
			idkb: (this.props.match.params.typemod!==undefined && this.props.match.params.typemod.includes("KB")) ? this.props.match.params.typemod.split("KB")[1]:"",
			userid: (decodedtoken!=="") ? decodedtoken.userid:"",
			showRecap: false,
			error: false,
			responsearrived: false,
			redirect: redi,
			n_exercises_step: [],
			total_exercises_answered: 0,
			total_exercises_answered_nowrapup: 0,
			missing_exercises: "",
			contentWarning: "",
			showWarning: false,
			sectionsRead:[],
			contentList: [],
			lastOpened: {},
			redirectDB: false,
			completed_sections_step: []
		};
	}

	showRecapModal = (iskb=false) => {
		//if it is knowledge base, goes back to kb page
		if (iskb) {
			this.setState({ redirectDB: true });
		}
		//Check if exercises are completed before showing wrap up (recap)
		let totalActivities = parseInt(this.state.module.n_exercises_all);
		if (totalActivities!==this.state.total_exercises_answered_nowrapup) {
			let auxString = this.state.missing_exercises.replace('and the Wrap-up','');
			auxString = auxString.replace('the Wrap-up','');
			this.setState({ contentWarning: auxString });
			this.setState({ showWarning: true });
		}
		else {
			this.setState({ showRecap: true });
		}
	}

	hideWarning = () => {
		this.setState({ showWarning: false });
	}


	// This function handles the change in answers for the module/guide
	handleChange_answers = (array_answers) => {
		// Initialize variables
		let lengtharray = 0;
		let lengtharray_nowrapup = 0;
		let nsteps = this.state.module.steps.length+1;
		let answers_per_step = Array(nsteps).fill(0);

		// For total
		let completed_sections_step = Array(nsteps).fill(0);

		// Loop through each answer in the array
		for (var key in array_answers) {
			// Skip if it's a content card
			if (key === "cIdeas")
				continue;
			let step = array_answers[key].step;
			if (array_answers[key].type === "ContentCard") {
				completed_sections_step[step-1] += 1;
				continue;
			}
			if (key === "clearnings") {
				continue;
			}		
			answers_per_step[step-1] += 1;
			completed_sections_step[step-1] += 1;
			lengtharray += 1;
			lengtharray_nowrapup += 1;
		}

		// Check for missing activities
		let steps= this.state.module.steps;
		let missing_activities_aux = [];
		let missing_string = "";
		steps.map((value, index) => {
			if (answers_per_step[index]!==value.n_exercises) {
				missing_activities_aux.push(index+1); 
			}
		});
		if (answers_per_step[answers_per_step.length-1]!==1)
			missing_activities_aux.push("the Wrap-up"); 
		for (var i=0;i<missing_activities_aux.length;i++) {
			if (i==0)
				missing_string += missing_activities_aux[i];
			else if (missing_activities_aux.length>1 && i==missing_activities_aux.length-1)
				missing_string += " and "+missing_activities_aux[i];
			else	
				missing_string += ", "+missing_activities_aux[i];
		}

		// Set the state with the updated values
		this.setState(
			{
				n_exercises_step:answers_per_step,
				total_exercises_answered:lengtharray,
				total_exercises_answered_nowrapup:lengtharray_nowrapup, 
				missing_exercises: missing_string,
				completed_sections_step: completed_sections_step
			}
		);
	};


	updateOpenSection = (index,step,opensect=false,itemsRef=[]) => {
		this.setState((state) => {
			const sa = state.sectionsRead;
			if ((opensect && !this.state.sectionsRead[step-1][index-1]) || !opensect)
				sa[step-1][index-1] = !sa[step-1][index-1];
			return { sectionsRead: sa };
		}, () => {});
	};

	isexpired = () => {
		this.setState({
			redirect: true,
		});
	}
	/*logout*/
	renderRedirectLogin = () => {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}
	};
	renderRedirectDB = () => {
        if (this.state.redirectDB) {
            return <Redirect to={"/admin/knowledgebasesub/"+this.state.idkb} />;
        }
	};

	getjsons() {		
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(get_jsons + this.props.match.params.guideName+ "?wpml_language="+ useLang, {
				headers: { Authorization: authstr },
			})
			.then((response) => {
				if (response.data==="")
					this.setState({error:true});
				this.setState(
					(prevState) => {
						const newModule = JSON.parse(response.data);
						const recap = newModule.steps.pop()['sections'][0];
						let aux = { ...newModule, recap: { ...recap } };
						const { pageNum } = this.props.match.params;
						let sectionsRead1 = Array(aux.steps.length).fill([]);
						let contentList1 = [];
						//go throgh each section 
						//and fill out the array of content
						for (let i=0;i<aux.steps.length;i++) {							
							sectionsRead1[i] = Array(aux.steps[i].sections.length).fill(false);
							let sectionsTitles = [];
							for (let row of aux.steps[i].sections) {
								if (row.title!=="") {
									let icon = 0;
									switch (row.type) {
										case "example":
										case "interactive_example":
										case "interactive_example_b":
											icon=1;
											break;
										case "exercise":
											icon=2;
											if (row.goal!=="")
												icon=3;
											break;
										default:
											break;
									}
									sectionsTitles[row.id] = {title: row.title, iconIndex: icon, completed: false};
									
								}
							}
							contentList1[i] = {title: aux.steps[i].title,sections:sectionsTitles};							
						}
						return {
							module: { ...newModule, recap: { ...recap } },
							loading: false,
							sectionsRead: sectionsRead1,
							contentList: contentList1,
							lastOpened: {step: -1, section: -1}
						};
					});
			})
			.catch((err) => {
				this.setState({error:true});
				if (err.response!==undefined)
					console.log(err.response.data.message);
				console.log("Error:");
				console.log(err);
			});
	}

	componentDidMount() {
		//get_jsons
		this._isMounted = true;
		this.getjsons();

	}

	renderRedirect = () => {
		if (this.state.error) {
		  return <Redirect to="/admin/guides" />;
		}
	 
	};

	/*
	 * Handles the rendering of the whole module/guide and pagination
	 */
	render() {
		const { match, classes } = this.props;
		const { guideName, pageNum } = match.params;

		const guideInfo = this.state.module;
		const sectionManagerExtraProps = {};

		if (!this.state.loading && !this.state.error) {
			let stepPagination = guideInfo.steps.slice(0);
			stepPagination.push({title:"Wrap-up",n_exercises:1});
			let totalActivities = parseInt(guideInfo.n_exercises_all);
			let moduleName = 	guideInfo.stepZero.title;
			let moduleImg = 	guideInfo.stepZero.img;

			if (pageNum < 1) {
				//Use this to prepare information needed by StepZero component
				//We need steps titles in array
				guideInfo.stepZero['steps'] = [];
				guideInfo.stepZero['startLink'] = `/guides/${guideName}/1/${this.state.typemod}/`;
				guideInfo.stepZero.steps = guideInfo.steps.map(({ title }, index) => title);
				sectionManagerExtraProps['stepZero'] = guideInfo.stepZero;
			}
			return (
				<div>
					{this.renderRedirectLogin()}
					{this.renderRedirectDB()}
					<ScrollToTop />

					<Pagination
						steps={stepPagination}
						iskb={this.state.iskb}
						numPages={guideInfo.steps.length}
						basePath={`/guides/${guideName}`}
						typemode={this.state.typemod}
						currentPage={pageNum}
						showRecapModal={this.showRecapModal}
					/>

					<GuideSectionManager
						isExpired={this.isexpired}
						backNavPath={`/guides/${guideName}`}
						step={guideInfo.steps[pageNum > 0 ? pageNum - 1 : 0]}
						readContent={this.state.sectionsRead}
						openedSection={this.updateOpenSection}
						stepn={pageNum}
						iskb={this.state.iskb}
						typemode={this.state.typemod}
						guidnam={guideName}
						colorTitle={guideInfo.stepZero.colorTitle}
						moduleName={moduleName}
						moduleImg={moduleImg}
						completedSectionsStep = {this.state.completed_sections_step}
						contentList={this.state.contentList}
						lastOpened={this.state.lastOpened}
						totalexercises={totalActivities}
						totalexercises_ans={this.state.total_exercises_answered}
						missingExercises={this.state.missing_exercises}
						numPages={guideInfo.steps.length}
						modpdfurl={guideInfo.stepZero.urlpdf}
						{...sectionManagerExtraProps}
						answersCount={this.handleChange_answers}
						RecapProps={
							{
								...this.state.module.recap,
								showRecap: this.state.showRecap,
								handleHideLeaveModalWarning: this.hideWarning,
								contentWarning: this.state.contentWarning,
								showWarning: this.state.showWarning,
								onHideRecap: () => this.setState({ showRecap: false }),
								showRecapModal: this.showRecapModal,
							}
						}
					/>

					
				</div>
			);
		} else {
			return (
			<LoadingOverlay
					active={this.state.loading}
					spinner={<BounceLoader />}
					styles={{
						overlay: (base) => ({
							...base,
							position: 'fixed',
						})}
					}
				>
					<div>{this.renderRedirectLogin()}{this.renderRedirect()}</div>
				</LoadingOverlay>
			)
		}
	}
}

export default withTranslation()(Guide);
